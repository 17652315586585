<template>
  <div id="transactions-list">
    <v-card>
      <v-card-title>決済実績一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="mbr_sei_kana"
            placeholder="セイ"
            outlined
            dense
            hide-details
            @change="changeSeiKana"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="mbr_mei_kana"
            placeholder="メイ"
            outlined
            dense
            hide-details
            @change="changeMeiKana"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="setlResult"
            :items="setlResults"
            placeholder="決済結果"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateStartMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :nudge-right="40"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="決済日From"
                dense
                hide-details
                readonly
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="決済日To"
                dense
                outlined
                hide-details
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="settlementList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.setl_code`]="{ item }">
          <a href="javascript:void(0)" @click="showTransaction(item)">{{ item.setl_code }}</a>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                href="javascript:void(0);"
                :style="item.mbr_status === 9 ? 'text-decoration: line-through red; color:#696969' : 'text-decoration: underline; color:#696969' "
                v-on="on"
              >{{ item.mbr_sei }} {{ item.mbr_mei }}</a>
            </template>
            <span>{{ item.mbr_sei }}   {{ item.mbr_mei }}</span><br>
            <span>{{ item.mbr_sei_kana }}   {{ item.mbr_mei_kana }}</span><br>
            <span>{{ item.mbr_zip }}</span><br>
            <span>{{ item.mbr_address1 }}{{ item.mbr_address2 }}</span><br>
            <span>{{ item.mbr_email }}</span><br>
            <span>{{ item.mbr_mobile }}</span><br>
          </v-tooltip>
        </template>

        <template v-slot:[`item.setl_amount`]="{ item }">
          <span>{{ formatData(item.setl_amount) }}</span>
        </template>

        <template v-slot:[`item.setl_result`]="{ item }">
          <v-chip
            v-show="getSetlResult(item.setl_result)"
            outlined
            :color="getColor(item.setl_result)"
          >
            {{ getSetlResult(item.setl_result) }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="showTransaction(item)"
              >
                {{ icons.mdiEye }}
              </v-icon>
            </div>
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
          </div>
        </template>
        <template v-if="totalPriceShow" v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              style="background: #fafafa"
              :colspan="3"
            >
              <label>合計価格(円)</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ tableTotalPrice }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="6"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <transaction-history
      :item="selectedItem"
      :dialog="dialog"
      @closeForm="closeForm"
    ></transaction-history>
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiPencil,
  mdiEye,
  mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'
import TransactionHistory from '@/views/bimi/payment/Transactions/TransactionHistory.vue'

export default {
  components: {
    TransactionHistory,
  },
  data: () => ({
    dialog: false,
    selectedItem: '',
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    setlResult: '',
    mbr_mei_kana: '',
    mbr_sei_kana: '',
    search: '',
    setlResults: [{
      text: '成功', value: 1,
    }, {
      text: '失敗', value: 99,
    }],
    icons: {
      mdiCalendar,
      mdiPencil,
      mdiEye,
      mdiExportVariant,
    },
    headers: [
      {
        text: '決済ID',
        value: 'setl_id',
        align: 'left',
        width: '7%',
        fixed: true,
      },
      {
        text: '決済コード',
        value: 'setl_code',
        align: 'left',
        width: '25%',
        fixed: true,
      },
      {
        text: '会員氏名',
        align: 'left',
        sortable: true,
        value: 'name',
        width: '10%',
        fixed: true,
      },
      {
        text: 'セイ',
        value: 'mbr_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'mbr_mei_kana',
        align: ' d-none',
      },
      {
        text: '姓',
        value: 'faq_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'faq_mei',
        align: ' d-none',
      },
      {
        text: '決済金額',
        value: 'setl_amount',
        width: '10%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '決済回数',
        value: 'setl_times',
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '決済日時',
        value: 'setl_dt',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '決済結果',
        value: 'setl_result',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '8%',
        align: 'right',
        fixed: true,
      },
      {
        text: '姓',
        value: 'mbr_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'mbr_mei',
        align: ' d-none',
      },
    ],
    totalPriceShow: false,
  }),
  computed: {
    ...mapState('settlementStore', ['settlementList']),

    tableTotalPrice() {
      let totalPrice = 0
      this.settlementList.forEach(sml => {
        totalPrice += sml.setl_amount
      })

      return totalPrice.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    getSetlResult() {
      return function (data) {
        if (data === 1) return '成功'
        if (data === 99) return '失敗'

        return ''
      }
    },
  },
  watch: {
    // mbr_sei_kana(newValue) {
    //   this.changeRouteQuery('mbr_sei_kana', newValue)
    //   this.loadData()
    // },
    // mbr_mei_kana(newValue) {
    //   this.changeRouteQuery('mbr_mei_kana', newValue)
    //   this.loadData()
    // },
    setlResult(newValue) {
      this.changeRouteQuery('setl_result', newValue)
      this.loadData()
      if (newValue === 1 || newValue === 99) {
        this.totalPriceShow = true
      } else {
        this.totalPriceShow = false
      }
    },
    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      this.loadData()
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  methods: {
    ...mapActions('paymentHistoryStore', ['loadPaymentHistory']),
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('settlementStore', ['loadSettlementList']),
    changeSeiKana() {
      this.changeRouteQuery('mbr_sei_kana', this.mbr_sei_kana)
      this.loadData()
    },
    changeMeiKana() {
      this.changeRouteQuery('mbr_mei_kana', this.mbr_mei_kana)
      this.loadData()
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadSettlementList(this.$route.query).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.mbr_sei_kana = this.$route.query.mbr_sei_kana ?? ''
      this.mbr_mei_kana = this.$route.query.mbr_mei_kana ?? ''
      // eslint-disable-next-line radix
      this.setlResult = this.$route.query.setl_result ? parseInt(this.$route.query.setl_result) : ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
    showTransaction(item) {
      this.selectedItem = item

      // load TransactionHistory
      this.setOverlayStatus(true)
      this.loadPaymentHistory({
        orderID: item.setl_code,
      }).then(() => {
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },
    closeForm() {
      this.dialog = false

      // this.loadData()
    },
    getColor(status) {
      if (status === 1) {
        return 'success'
      }

      return 'warning'
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
