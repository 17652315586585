<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span
          class="text-h5"
        >決済履歴</span>
      </v-card-title>
      <v-card-text>
        <v-container class="ma-0 pa-0" style="max-width:100%">
          <template v-if="apierror.status == 'error'">
            <div
              v-for="msg of apierror.messages"
              :key="msg"
            >
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-row align="center" class="px-2 ma-0 mb-4">
            <span style="font-size:16px">決済結果:</span>
            <v-chip
              v-show="getSetlResult"
              class="ml-4"
              outlined
              :color="getColor"
            >
              {{ getSetlResult }}
            </v-chip>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="paymentHistory"
            hide-default-footer
            sort-by
            class="elevation-1 mx-2"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ $formatDt(item.created_at, 'yyyy-MM-dd hh:mm:ss') }}</span>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          class="mx-2"
          color="success"
          @click="closeForm()"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'

export default {
  props: ['dialog', 'item'],
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    paymentHistory: [],
    headers: [
      {
        text: 'No.',
        value: 'id',
        align: 'left',
        width: '5%',
        sortable: false,
        fixed: true,
      },
      {
        text: '決済コード',
        value: 'orderID',
        align: 'left',
        width: '25%',
        fixed: true,
      },
      {
        text: '状態',
        value: 'payStatus',
        width: '10%',
        align: 'center',
        sortable: false,
        fixed: true,
      },
      {
        text: '決済日時',
        value: 'created_at',
        align: 'left',
        width: '20%',
        sortable: false,
        fixed: true,
      },
      {
        text: 'メッセージ',
        value: 'errorMessage',
        sortable: false,
        width: '40%',
        align: 'left',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('paymentHistoryStore', ['paymentHistoryList']),
    getSetlResult() {
      if (this.item && this.item.setl_result === 1) return '成功'
      if (this.item && this.item.setl_result === 99) return '失敗'

      return ''
    },
    getColor() {
      if (this.item && this.item.setl_result === 1) {
        return 'success'
      }

      return 'warning'
    },
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.openForm()
      }
    },
  },
  methods: {
    ...mapMutations('paymentHistoryStore', ['clearPaymentHistory']),
    ...mapActions('paymentHistoryStore', ['loadPaymentHistory']),
    openForm() {
      this.paymentHistory = [...this.paymentHistoryList]

      this.paymentHistoryList.forEach((ph, i) => {
        this.paymentHistory[i].id = i + 1
        if (ph.errorMessage) {
          this.paymentHistory[i].errorMessage = JSON.stringify(JSON.parse(ph.errorMessage))
        }
      })
    },
    closeForm() {
      this.$emit('closeForm')
      this.clearPaymentHistory()
      this.paymentHistory = []
      this.apierror.messages = []
    },
  },
}
</script>
